import React, { useEffect, useReducer } from 'react';
import { graphql, PageProps } from 'gatsby';
import { Grid } from '@material-ui/core';

import { health } from '@constants';
import {
    CovidMap,
    MainWrapper,
    MobileScreenMessage,
    Header,
    Report,
    // Legend,
    TotalIncidentsCount,
    Sidebar,
    TFilter,
    TSelectedCountry,
    Seo,
} from '@components';
import {
    StyledMapContainer,
    THealthPageProperties as THealthPageProperties,
    StyledReportContainer,
    TInitialState,
    TAction,
} from '.';
import { THealthSecurityIncident } from '@types';
import { calculateGlobalTotals } from '@helpers';

const INITIAL_STATE: TInitialState = {
    filter: {
        category: health.filters.categoryOptions[0].value,
        fromDate: health.filters.dates.from,
        toDate: health.filters.dates.to,
    },
    incidents: undefined,
    selectedCountry: undefined,
    globalStatistics: undefined,
};

function reducer(state: TInitialState, action: TAction) {
    switch (action.type) {
        case 'setIncidents':
            return { ...state, incidents: action.value };
        case 'updateFilters':
            return { ...state, filter: action.value };
        case 'setSelectedCountry':
            return {
                ...state,
                selectedCountry: action.value,
            };
        case 'clearSelectedCountry':
            return { ...state, selectedCountry: undefined };
        case 'setGlobalStatistics':
            return { ...state, globalStatistics: action.value };
        default:
            return INITIAL_STATE;
    }
}

function mapAPIValueToGraphQL({
    key,
    value,
}: {
    key: string;
    value: string | number;
}): string {
    if (key === 'displayStatus' && value === ' Yes') return 'Yes';
    if (value == undefined) return '';
    if (key === 'isoDate' && typeof value === 'string')
        return `${value.slice(8, 10)}/${value.slice(5, 7)}/${value.slice(
            0,
            4
        )}`;
    return value.toString();
}

function mapAPIObjectToGraphQLNodes(jsonResponse): THealthSecurityIncident[] {
    return jsonResponse.map(object =>
        Object.fromEntries(
            Object.entries(object).map(([key, value]) => [
                key,
                mapAPIValueToGraphQL({ key, value }),
            ])
        )
    );
}

export default function Health({ data }: PageProps<THealthPageProperties>) {
    const [state, dispatch] = useReducer(reducer, INITIAL_STATE);
    const { markdownRemark } = data;

    useEffect(() => {
        const fetchData = async () => {
            const response = await fetch(
                `https://sind-api.herokuapp.com/mapaction/v1/health`
            );
            const jsonResponse = await response.json();
            const convertedResponse = mapAPIObjectToGraphQLNodes(jsonResponse);

            dispatch({
                type: 'setIncidents',
                value: convertedResponse,
            });
        };

        fetchData().catch(console.error);
    }, []);

    function handleFilterChange(filter: TFilter): void {
        dispatch({ type: 'updateFilters', value: filter });
    }

    function handleSettingSelectedCountry(
        selectedCountry: TSelectedCountry
    ): void {
        dispatch({ type: 'setSelectedCountry', value: selectedCountry });
    }

    function handleClearingSelectedCountry(): void {
        dispatch({ type: 'setSelectedCountry', value: undefined });
    }

    function handleSettingGlobalStatistics(
        filteredIncidents: THealthSecurityIncident[]
    ): void {
        const globalStatistics = calculateGlobalTotals(filteredIncidents);

        dispatch({ type: 'setGlobalStatistics', value: globalStatistics });
    }

    return (
        <StyledMapContainer>
            <Seo
                title={markdownRemark.frontmatter.title}
                description={markdownRemark.frontmatter.metaDescription}
            />
            <Header
                title={markdownRemark.frontmatter.heading}
                funderLogos={health.funders.filter(
                    funder => funder.view === 'header'
                )}
            />
            <MainWrapper>
                <Grid item xs={2}>
                    <Sidebar
                        filter={state.filter}
                        setFilter={handleFilterChange}
                        categoryOptions={health.filters.categoryOptions}
                        description={markdownRemark.frontmatter.sideBarCopy.replace(
                            'INSERT YEAR',
                            `${new Date().getFullYear()}`
                        )}
                        collaboratorLogos={health.funders.filter(
                            funder => funder.view === 'sidebar-collaborator'
                        )}
                        funderLogos={health.funders.filter(
                            funder => funder.view === 'sidebar'
                        )}
                    />
                </Grid>
                <Grid item xs={10}>
                    <CovidMap
                        filter={state.filter}
                        incidents={state.incidents}
                        selectedCountry={state.selectedCountry}
                        setSelectedCountry={handleSettingSelectedCountry}
                        clearSelectedCountry={handleClearingSelectedCountry}
                        setGlobalStatistics={handleSettingGlobalStatistics}
                        dataSourceAttribution={
                            markdownRemark.frontmatter.dataSourceAttribution
                        }
                    />
                    <TotalIncidentsCount
                        filter={state.filter}
                        filterCategory={health.filters.categoryOptions.find(
                            option => option.value === state.filter.category
                        )}
                        globalStatistics={state.globalStatistics}
                        showGlobalStatistics
                    />
                    {/* <Legend filter={state.filter} /> */}
                </Grid>
            </MainWrapper>
            <MobileScreenMessage />
            {Boolean(state.selectedCountry) && (
                <StyledReportContainer>
                    <Report
                        filter={state.filter}
                        filterCategory={health.filters.categoryOptions.find(
                            option => option.value === state.filter.category
                        )}
                        selectedCountry={state.selectedCountry}
                        clearSelectedCountry={handleClearingSelectedCountry}
                    />
                </StyledReportContainer>
            )}
        </StyledMapContainer>
    );
}

export const pageQuery = graphql`
    query HealthPageTemplate {
        markdownRemark(frontmatter: { path: { eq: "health" } }) {
            frontmatter {
                path
                title
                heading
                metaDescription
                sideBarCopy
                dataSourceAttribution
            }
        }
    }
`;
