import styled from 'styled-components';

export const StyledMapContainer = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    min-width: 100%;
    height: 100%;
`;

export const StyledReportContainer = styled.div`
    position: fixed;
    top: 100px;
    right: 0;
    z-index: 1300;
    width: 316px;
    height: calc(100vh - 100px);
    padding: ${({ theme }) => theme.spacing(2)}px;
    overflow-y: scroll;
    background: ${({ theme }) => theme.palette.colors.white};
    border-left: 1px solid ${({ theme }) => theme.palette.colors.grey100};
`;
